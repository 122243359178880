import gallery1 from '../assets/gallery/gallery1.jpg'
import gallery2 from '../assets/gallery/gallery2.jpg'
import gallery3 from '../assets/gallery/gallery3.jpg'
import gallery4 from '../assets/gallery/gallery4.jpg'
import gallery5 from '../assets/gallery/gallery5.jpg'
import gallery6 from '../assets/gallery/gallery6.jpg'
import gallery7 from '../assets/gallery/gallery7.jpg'
import gallery8 from '../assets/gallery/gallery8.jpg'
import gallery9 from '../assets/gallery/gallery9.jpg'
import gallery10 from '../assets/gallery/gallery10.jpg'
import gallery11 from '../assets/gallery/gallery11.jpg'
import gallery12 from '../assets/gallery/gallery12.jpg'
import gallery13 from '../assets/gallery/gallery13.jpg'
import gallery14 from '../assets/gallery/gallery14.jpg'
import gallery15 from '../assets/gallery/gallery15.png'

export const gallery = [
  gallery1, gallery2, gallery3, gallery4, gallery5, gallery6, gallery7, gallery8, gallery9, gallery10, gallery11, gallery12, gallery13, gallery14, gallery15
]